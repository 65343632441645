import create from 'zustand';
import { combine } from 'zustand/middleware';

import toast from '@/helpers/toast';

const STORAGE_AUTH = '@mais1kds/auth';
const isServer = typeof window === 'undefined';

const getDefaultValues = () => {
  if (!isServer) {
    try {
      const auth = JSON.parse(localStorage.getItem(STORAGE_AUTH) || '{}');
      return {
        apiKey: auth ? auth.apiKey : '',
        uid: auth ? auth.uid : '',
        lang: auth ? auth.lang : '',
      };
    } catch {
      toast.error('Ocorreu um erro');
    }
  }

  return {
    apiKey: '',
    uid: '',
    lang: '',
  };
};

export const usePin = create(
  combine(getDefaultValues(), (set, get) => ({
    setLang: (lang: string) => {
      const state = get();
      try {
        localStorage.setItem(STORAGE_AUTH, JSON.stringify({ ...state, lang }));
      } catch {
        toast.error('Ocorreu um erro');
      }
      set({ ...state, lang });
    },
    setPin: (apiKey: string, uid: string) => {
      try {
        localStorage.setItem(STORAGE_AUTH, JSON.stringify({ apiKey, uid }));
      } catch {
        toast.error('Ocorreu um erro');
      }
      set({ apiKey, uid });
    },
    logout: () => {
      try {
        localStorage.removeItem(STORAGE_AUTH);
        localStorage.removeItem('@mais1kds/firebaseToken');
        localStorage.removeItem('@mais1kds/company');
      } catch {
        toast.error('Ocorreu um erro');
      }
      set({ apiKey: '', uid: '' });
    },
  })),
);

export { STORAGE_AUTH };
