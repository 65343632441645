import { createStandaloneToast } from '@chakra-ui/react';

const standaloneToast = createStandaloneToast();

const toast = {
  success: (message: string) => {
    standaloneToast({
      title: 'Sucesso!',
      description: message,
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top-right',
    });
  },
  error: (message: string) => {
    standaloneToast({
      title: 'Erro!',
      description: message,
      status: 'error',
      duration: 2000,
      isClosable: true,
      position: 'top-right',
    });
  },
  info: (message: string) => {
    standaloneToast({
      title: 'Info',
      description: message,
      status: 'info',
      duration: 2000,
      isClosable: true,
      position: 'top-right',
    });
  },
};

export default toast;
